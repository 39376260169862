<template>
    <div>
        <button :class="buttonClasses"
                type="button"
                @click="openBooking()">
            {{i18nButtonText}}
        </button>
        <modal v-if="showModal" @close="showModal = false" :closeable="true" :small="true" ref="modal">
            <h2 class="mb-4" slot="header">
                {{i18nModalHeader}}
            </h2>
            <div slot="body">
                <iframe :src="bookingUrl"
                        frameBorder="0"
                        width="100%"
                        id="bookingIframe"
                        ref="bookingIframe"
                        allowpaymentrequest
                        scrolling="no"></iframe>
            </div>
        </modal>
    </div>
</template>
<script>
  import Modal from './Modal';

  export default {
    name: 'BookingButton',
    components: {
      Modal
    },
    props: [
      'i18nButtonText',
      'i18nModalHeader',
      'bookingUrl',
      'buttonClasses'
    ],
    data() {
      return {
        showModal: false
      }
    },
    mounted() {
      setTimeout(() => {
        function receiveMessage(event) {
          if (event && event.data) {
            var bookingFrame = document.getElementById('bookingIframe');
            if (bookingFrame && event.data.type === 'heightChanged') {
              bookingFrame.height = event.data.height + 50;
            }
          }
        };
        window.addEventListener('message', receiveMessage, false);
      }, 10);
    },
    methods: {
      openBooking() {
        if (this.$isMobile) {
          window.location.href = this.bookingUrl;
        } else {
          this.showModal = true
        }
      }
    }
  }
</script>

<style lang="scss">
    @import '../scss/variables';
</style>
